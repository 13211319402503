import { LazyPage } from '../components/loadable';

// import DanhGiaDinhKyLayout from './pages/danh-gia/dinh-ky';
// import DanhGiaThuongXuyenLayout from './pages/danh-gia/thuong-xuyen';

export { default as HomePage } from './home/home';
export * from './danh-muc'
export const LopBoMonPage = LazyPage(import('./lop-bo-mon'))
export const LopMonHocPage = LazyPage(import('./lop-mon-hoc'))
export const ConfigSoDiemTieuHocPage = LazyPage(import('./so-diem-tieu-hoc'))
export const SoDiemPage = LazyPage(import('./so-diem-trung-hoc'))
export const GanSoDiemLayout = LazyPage(import('./gan-so-diem-layout'))
export const SoDiemLayout = LazyPage(import('./so-diem-layout'))

export const AppMenuPage = LazyPage(import('./app-phuhuynh/app-menu'));
export const AppMenuTruongConfig = LazyPage(import('./app-phuhuynh/app-menu-truong'));
export const AppAccountPage = LazyPage(import('./app-phuhuynh/app-account'));
export const AppMenuListConfig = LazyPage(import('./app-phuhuynh/app-menu/list-config'));
export const BaiVietPage = LazyPage(import('./bai-viet'));
export const BaiVietChoDuyetPage = LazyPage(import('./bai-viet/bai-viet-cho-duyet'));
export const BaiVietDetailPage = LazyPage(import('./bai-viet/bai-viet-detail'));
export const AppXinNghiPage = LazyPage(import('./app-xinnghi'));
export const AppXinNghiDetailPage = LazyPage(import('./app-xinnghi/detail-page'));
export const DiemDanhPage = LazyPage(import('./diem-danh'));
export const NhanXetHangNgayPage = LazyPage(import('./nhan-xet-hang-ngay'));
export const NhanXetHangNgayCommentPage = LazyPage(import('./nhan-xet-hang-ngay/comment'));
export const BaiTapPage = LazyPage(import('./bai-tap'));
export const NhapDiemPage = LazyPage(import('./nhap-diem'));
export const LopHanhChinhPage = LazyPage(import('./lop-hanh-chinh'));
export const GanSoDiemPage = LazyPage(import('./gan-so-diem'));
export const ThongTuPage = LazyPage(import('./thong-tu'));
export const DanhMucTuanTietPage = LazyPage(import('./danh-muc'));
export const DiemVanMinhKetQuaPage = LazyPage(import('./diemvanminh/diemvanminh-ketqua'));
export const DiemVanMinhTieuChiPage = LazyPage(import('./diemvanminh/diemvanminh-tieuchi'));
export const TongHopDinhKyPage = LazyPage(import('./tong-hop-dinh-ky'));

export const GanSoNLPCPage = LazyPage(import('./gan-so-nlpc'));
export const DGDKNLPCPage = LazyPage(import('./danh-gia/dinh-ky/nlpc'));
export const ThoiKhoaBieuPreviewPage = LazyPage(import('./thoi-khoa-bieu/thoi-khoa-bieu-preview'));
export const ThoiKhoaBieuConfigPage = LazyPage(import('./thoi-khoa-bieu/thoi-khoa-bieu-config'));
export const ThoiKhoaBieuPreviewGiaoVienPage = LazyPage(import('./thoi-khoa-bieu/thoi-khoa-bieu-giaovien'));
// export const ThoiKhoaBieuImport = LazyPage(import('./thoi-khoa-bieu/thoi-khoa-bieu-import'));
export const BaoCaoTienBoPage = LazyPage(import('./bao-cao-tien-bo'));
export const XepHangHocSinhPage = LazyPage(import('./xep-hang-hoc-sinh'));
export const LopMonHocLayout = LazyPage(import('./lop-mon-hoc-layout'));

export const DanhGiaDinhKyLayout = LazyPage(import('./danh-gia/dinh-ky'));
export const DanhGiaThuongXuyenLayout = LazyPage(import('./danh-gia/thuong-xuyen'));
export const KhoaDanhGiaPage = LazyPage(import('./danh-gia/khoa-danh-gia'));
export const BaoCaoDiemDanhPage = LazyPage(import('./bao-cao-diem-danh'));
export const HocSinhProfilePage = LazyPage(import('./hoc-sinh'));
export const BaoCaoNhanXetHangNgayTrungBinhPage = LazyPage(import('./bao-cao-nhan-xet-hang-ngay-tb'))
export const DangKyMonHocPage = LazyPage(import('./dangky-monhoc'));
export const KetQuaDangKyMonHoc = LazyPage(import('./dangky-monhoc-ketqua'));
export const ChamTietCaNhanPage = LazyPage(import('./cham-tiet/cham-tiet-ca-nhan'));
export const DuyetChamTietPage = LazyPage(import('./cham-tiet/duyet-cham-tiet'));
export const QuanLyChamTietPage = LazyPage(import('./cham-tiet/quan-ly-cham-tiet'));
export const PhanCongGiangDayPage = LazyPage(import('./phan-cong-giang-day'));

export const FormKhaoSatPage = LazyPage(import('./form-khaosat'));
export const KhaoSatPage = LazyPage(import('./khao-sat'));
export const KhaoSatResultPage = LazyPage(import('./khao-sat-result'));
export const PhoDiemPage = LazyPage(import('./pho-diem'));
export const DiemDanhAnPage = LazyPage(import('./diem-danh-an'));
export const MeetingPage = LazyPage(import('./meeting'));
export const MeetingDetail = LazyPage(import('./meeting-detail'));
export const BaoCaoDiemDanhAnPage = LazyPage(import('./bao-cao-diem-danh-an'));
